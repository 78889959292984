import {
  Button,
  VStack,
  Image,
  Text,
  Flex,
  Box,
  Center,
  Stack,
} from "@chakra-ui/react";
import getConfig from "~/libs/config";
import { useTranslation, useLocaleNavigate, useLocale } from "~/libs/i18n";
import { Capacitor } from "@capacitor/core";
import { FC, useState, useEffect } from "react";
import CustomImage from "~/components/CustomImage";

import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";

const AppleLoginButton: FC = () => {
  const navigate = useLocaleNavigate();
  const platform = Capacitor.getPlatform();
  const t = useTranslation();
  const [hostname, setHostname] = useState<string>("");

  useEffect(() => {
    setHostname(window?.location?.hostname);
  }, []);

  const handleAppleLogin = async () => {
    const appleClientId =
      getConfig().REMIX_PUBLIC_APPLE_CLIENT_ID ?? "com.timable.app.applesignin";

    let options: SignInWithAppleOptions = {
      clientId: appleClientId,
      redirectURI: `https://${hostname}/oauth/apple/redirect`,
      scopes: "email name",
    };

    await SignInWithApple.authorize(options)
      .then((result: SignInWithAppleResponse) => {
        if (result?.response?.identityToken) {
          navigate(
            `/oauth/apple?accessToken=${result?.response?.identityToken}&type=apple`
          );
        }
      })
      .catch((error) => {
        console.log("SignInWithApple error", error);
        // Handle error
      });
  };

  const RenderButtonByPlatform: FC = () => {
    if (platform === "web") {
      return (
        <Button variant="link" onClick={handleAppleLogin}>
          <VStack>
            <CustomImage src={"/assets/images/sso/apple.svg"} />
            <Text>{t("Apple")}</Text>
          </VStack>
        </Button>
      );
    }

    return (
      <VStack w="full" onClick={handleAppleLogin}>
        <Box
          as="button"
          type="button"
          w="full"
          borderRadius={"8px"}
          border={"1px solid #000000"}
          bgColor={"#000000"}
        >
          <Flex alignItems={"stretch"} h="54">
            <Box borderRight={"1px solid #000000"} px={4}>
              <Center h="full">
                <CustomImage
                  src={"/assets/images/sso/app_apple_sso.png"}
                  w={"18px"}
                  h={"auto"}
                  alt="apple-icon"
                />
              </Center>
            </Box>
            <Text
              w="full"
              textAlign={"center"}
              alignSelf={"center"}
              color={"#FFF"}
            >
              {t("member.appleLogin")}
            </Text>
          </Flex>
        </Box>
      </VStack>
    );
  };

  return <RenderButtonByPlatform />;
};

export default AppleLoginButton;
