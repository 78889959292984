import {
  Button,
  VStack,
  Text,
  Checkbox,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box,
  Stack,
} from "@chakra-ui/react";
import { useSearchParams, useLocation } from "@remix-run/react";
import { useForm, UseFormRegister } from "react-hook-form";
import { useTranslation } from "~/libs/i18n";
import { useMemberContext } from "~/context/member";
import { HStack } from "@chakra-ui/react";
import { useLocaleNavigate } from "~/libs/i18n";
import { useEffect } from "react";
import { TextField, PasswordField } from "~/components/form/Fields";
import { Capacitor } from "@capacitor/core";
import FacebookLoginButton from "~/components/shared/FacebookLogin";
import AppleLoginButton from  "~/components/shared/AppleLoginButton";
import GoogleLoginButton from "~/components/shared/GoogleLogin";


type LoginForm = {
  email: string;
  password: string;
};

const FooterMessage = () => {
  const t = useTranslation();
  const navigate = useLocaleNavigate();
  return (
    <HStack justifyContent={"space-between"} pt={6} pb={4} w="full">
      <Box>
        <Text>{t("form.registrationMessage")}</Text>
      </Box>
      <Button variant={"outline"} onClick={() => navigate("/user/register")}>
        {t("registration")}
      </Button>
    </HStack>
  );
};

const LoginMember = () => {
  const t = useTranslation();
  const navigate = useLocaleNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginForm>();
  const { login, processing, errors: error } = useMemberContext();
  const [sp] = useSearchParams();
  const loginSubmit = ({ email, password }: LoginForm) => {
    login({ email, password, redirectUrl: sp.get("redirect_url") ?? "/" });
  };
  const platform = Capacitor.getPlatform();
  const isWeb = platform === "web";

  useEffect(() => {
    const suspended = sp.get("account");
    if (suspended) {
      onOpen();
    }
  }, []);

  const errorMessage = (error: {
    loginUserError: any;
    loginMemberError: any;
  }) => {
    const { loginUserError, loginMemberError } = error;
    if (loginUserError || loginMemberError) {
      return true;
    }
    return false;
  };

  const registerOptions = {
    email: {
      required: t("form.errors.required"),
      // pattern: {
      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      //   message: t("form.errors.invalidEmailAddress"),
      // },
    },
    password: {
      required: t("form.errors.required"),
    },
  };

  return (
    <VStack
      as="form"
      onSubmit={handleSubmit(loginSubmit)}
      alignItems="stretch"
      w="full"
      gap={4}
    >
      <TextField
        register={register as UseFormRegister<LoginForm>}
        errors={errors}
        name={"email"}
        type="text"
        option={registerOptions["email"]}
        labelText={t("form.loginEmail") as string}
        errorText={errors?.["email"]?.message}
      />

      <PasswordField
        register={register as UseFormRegister<LoginForm>}
        errors={errors}
        name={"password"}
        option={registerOptions["password"]}
        labelText={t("form.password") as string}
        errorText={errors?.["password"]?.message}
        disableReminder={true}
      />

      <HStack justifyContent="space-between">
        <Checkbox defaultChecked>{t("member.keepLoginStatus")}</Checkbox>
        <HStack gap={1}>
          <Button
            variant="link"
            color="brand.link-blue"
            onClick={() => navigate("/user/forgotPassword")}
          >
            {t("member.forgetPassword")}
          </Button>

          {/* <Divider orientation="vertical" h={4} />

          <Button
            variant="link"
            color="brand.link-blue"
            onClick={() => navigate("/user/reActivePassword")}
          >
            {t("member.oldMemberReset")}
          </Button> */}
        </HStack>
      </HStack>

      {errorMessage(error) && (
        <Text color={"red"}>{t(`form.errors.loginFail`)}</Text>
      )}

      <Button
        isLoading={processing}
        type="submit"
        variant="formButton"
        w="full"
        id="loginButton"
      >
        {t("member.login")}
      </Button>

      <FooterMessage />

      <HStack alignItems="center">
        <Divider orientation="horizontal" flex={1} />
        <Text>{t("member.thirdPartyLogin")}</Text>
        <Divider orientation="horizontal" flex={1} />
      </HStack>

      <Stack
        py={4}
        px={isWeb ? 4 : 0}
        pb={6}
        alignItems="center"
        direction={isWeb ? "row" : "column"}
        justifyContent="center"
      >
        <Stack
          direction={isWeb ? "row" : "column"}
          maxW={isWeb ? "xs" : "full"}
          w={"full"}
          justifyContent={"space-between"}
          gap={isWeb ? 2 : 4}
        >
          <AppleLoginButton />
          <GoogleLoginButton/>
          <FacebookLoginButton/>
        </Stack>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader>{t("member.deleteAccount")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" py={4} gap={4}>
              <Text>{t("member.accountSuspend")}</Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default LoginMember;
