import {
  VStack,
  Image,
  Heading,
  Box,
  Stack,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "~/libs/i18n";
import Section from "~/components/Section";
import LoginMessage from "~/components/user/LoginMessage";
import LoginMember from "~/components/form/LoginMember";
import CustomImage from "~/components/CustomImage";

const Login = () => {
  return (
    <Box pos="relative" bgColor={"brand.soft-yellow"} overflow="hidden">
      <CustomImage
        src={"/assets/images/member/image_02.svg"}
        alt="image_02"
        pos="absolute"
        top={-80}
        right={-80}
        zIndex={2}
      />
      <Section p={0} py={{ md: 20 }} pos={"relative"} zIndex={3}>
        <Stack
          direction={{ base: "row-reverse", md: "row" }}
          spacing={{ base: 0, md: 4 }}
        >
          <VStack flex={1} display={{ base: "none", lg: "flex" }}>
            <LoginMessage />
          </VStack>
          <Box flex={1} pos={"relative"}>
            <VStack
              flex={1}
              maxWidth={{ base: "full", md: "container.sm" }}
              mx={"auto"}
              alignItems="stretch"
              bgColor={"white"}
              border={1}
              rounded={{ lg: "lg" }}
              boxShadow={"md"}
              pos="relative"
              zIndex={3}
              className="loginSection"
            >
              <Flex
                direction="column"
                gap={4}
                pt={[4, 20]}
                px={[4, 16]}
                pos={"relative"}
                zIndex={3}
              >
                <HeaderMessage />

                <LoginMember />
              </Flex>
            </VStack>

            <Background />
          </Box>
        </Stack>
      </Section>
    </Box>
  );
};

const HeaderMessage = () => {
  const t = useTranslation();
  return (
    <VStack>
      <CustomImage src={"/assets/images/logo.svg"} alt="logo" />
      <Heading as="h5">{t("member.welcomeBack")}</Heading>
      <Text>{t("member.loginDescription")}</Text>
    </VStack>
  );
};

const Background = () => {
  return (
    <Box>
      <CustomImage
        src={"/assets/images/member/image_05.svg"}
        alt="image_05"
        pos="absolute"
        top={-4}
        left={-16}
        zIndex={2}
        maxW={100}
      />

      <CustomImage
        src={"/assets/images/member/image_03.svg"}
        alt="image_03"
        pos="absolute"
        bottom={-10}
        left={-40}
        zIndex={2}
        maxW={300}
      />

      <CustomImage
        src={"/assets/images/member/image_04.svg"}
        alt="image_04"
        pos="absolute"
        bottom={20}
        right={-16}
        zIndex={2}
        maxW={100}
      />
    </Box>
  );
};

export default Login;
