import {
  Button,
  VStack,
  Image,
  Text,
  Flex,
  Box,
  Center,
} from "@chakra-ui/react";
import { useTranslation } from "~/libs/i18n";
import { useLocaleNavigate } from "~/libs/i18n";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { Capacitor } from "@capacitor/core";
import { FC } from "react";
import CustomImage from "~/components/CustomImage";

const FacebookLoginButton: FC = () => {
  const navigate = useLocaleNavigate();
  const platform = Capacitor.getPlatform();
  const t = useTranslation();
  const handleFacebookLogin = async () => {
    const result = await FacebookLogin.login({ permissions: ["email"] });

    if (result?.accessToken) {
      navigate(
        `/oauth/facebook?accessToken=${result?.accessToken?.token}&type=facebook`
      );
    }
  };

  const RenderButtonByPlatform: FC = () => {
    if (platform === "web") {
      return (
        <Button variant="link">
          <VStack onClick={handleFacebookLogin} cursor={"pointer"}>
            <CustomImage src={"/assets/images/sso/facebook.svg"} />
            <Text>{t("Facebook")}</Text>
          </VStack>
        </Button>
      );
    }

    return (
      <Box
        as="button"
        type="button"
        w="full"
        borderRadius={"8px"}
        border={"1px solid #1877F2"}
        bgColor={"#1877F2"}
        onClick={handleFacebookLogin}
      >
        <Flex alignItems={"stretch"} h="54">
          <Box borderRight={"1px solid #135FBF"} px={4}>
            <Center h="full">
              <CustomImage
                src={"/assets/images/sso/app_facebook_sso.png"}
                w={"18px"}
                h={"auto"}
                alt="facebook-icon"
              />
            </Center>
          </Box>
          <Text
            w="full"
            textAlign={"center"}
            alignSelf={"center"}
            color={"#FFF"}
          >
            {t("member.facebookLogin")}
          </Text>
        </Flex>
      </Box>
    );
  };

  return <RenderButtonByPlatform />;
};

export default FacebookLoginButton;
